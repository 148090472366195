






import PageTemplate from "@/components/PageTemplate.vue";
import VotingStats from "@/components/Registrations/VotingStats.vue";

import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    VotingStats,
  },
})
export default class VotingStatsView extends Vue {
  RouteName = RouteName;
}
