


























































import { apiService } from "@/services/api.service";
import { Vue, Component } from "vue-property-decorator";
import { RegistrationStatsResponse } from "./@types";
import RegistrationStatBlock from "./RegistrationStatBlock.vue";

@Component({
  components: {
    RegistrationStatBlock,
  },
})
export default class VotingStats extends Vue {
  stats: RegistrationStatsResponse = {
    usersVoted: 0,
    totalVotes: 0,
    abstainedVotes: 0,
  };

  async created() {
    const [stats] = await Promise.all([
      apiService.axios
        .get<RegistrationStatsResponse>("/secure-voting/stats")
        .then((res) => res.data),
    ]);

    this.stats = {
      ...stats,
    };
  }

  getQuestionClause(linkText: string): string {
    const pattern = /.*>(.+)<.+/;
    const matches = pattern.exec(linkText);

    return matches ? `(${matches[1]})` : "";
  }
}
